import { IntlErrorCode, NextIntlProvider } from 'next-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import Script from 'next/script';

import '@/assets/styles/index.css';
import useAnalytics from '@/lib/analytics';
import { GTM_ID } from '@/lib/gtag';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function onError(error) {
  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    console.warn(error);
    return;
  }
}

function MyApp({ Component, pageProps, router }) {
  useAnalytics();

  return (
    <>
      <Script
        id="GoogleTagManagerScript"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
        }}
      />
      <Script
        id="sessionClear"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            sessionStorage.removeItem('requestKey')
          `,
        }}
      />
      <QueryClientProvider client={queryClient}>
        <NextIntlProvider
          messages={pageProps.messages || {}}
          locale={router.locale}
          defaultLocale={router.defaultLocale}
          onError={onError}
        >
          <Component {...pageProps} />
        </NextIntlProvider>
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
