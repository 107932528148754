import { useEffect } from 'react';
import { useRouter } from 'next/router';

import * as gtag from './gtag';

function useAnalytics() {
  const router = useRouter();

  function handleRouteChange(url) {
    gtag.event({ event: 'pageview', pagePath: url });
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
}

export default useAnalytics;
